import { useState, useRef } from "react";
import { auth } from "./firebaseApp";
import {
  signInWithPhoneNumber,
  PhoneAuthProvider,
  UserCredential,
  signInWithCredential,
  RecaptchaVerifier,
  getAuth,
  linkWithCredential,
  EmailAuthProvider,
  unlink,
  GoogleAuthProvider,
  signInWithPopup,
  PhoneAuthCredential,
} from "firebase/auth";
import { baseUrl } from "../src/lib/api/constants";
declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
  }
}

const useFirebaseServices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const firebaseError = useRef<string | null>(null);
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container", // The ID of the HTML element that contains the reCAPTCHA
      {
        size: "invisible",
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth,
    );
    window.recaptchaVerifier.render();
  };

  const handleReset = () => {
    setIsLoading(false);
    firebaseError.current = null;
    setVerificationId(null);
    localStorage.removeItem("verificationId");
  };

  const requestOTP = async (phoneNumber: string) => {
    setIsLoading(true);
    setupRecaptcha();

    try {
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
      setVerificationId(confirmationResult.verificationId);
      localStorage.setItem("verificationId", confirmationResult.verificationId);
      firebaseError.current = null; // Clear any previous errors
      return true;
    } catch (error) {
      firebaseError.current = (error as Error).message;
      console.error("Error during signInWithPhoneNumber:", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOTP = async (
    otp: string,
  ): Promise<{
    userCredential: UserCredential;
    idToken: string;
    credential: PhoneAuthCredential;
    userExists: boolean;
    onboarding_complete: boolean;
  } | null> => {
    setIsLoading(true);
    try {
      const storedVerificationId = verificationId || localStorage.getItem("verificationId");
      let userExists = false;
      if (storedVerificationId) {
        const credential = PhoneAuthProvider.credential(storedVerificationId, otp);

        try {
          const userCredential = await signInWithCredential(auth, credential);

          // Check if this is an existing account by looking at creation time
          if (userCredential.user.metadata.creationTime !== userCredential.user.metadata.lastSignInTime) {
            userExists = true;
          }

          // If we get here, this is a new account
          const idToken = await userCredential.user.getIdToken();
          localStorage.setItem("USER_TOKEN", idToken);

          const request = {
            idToken,
            rememberMe: true,
          };
          const loginResponse = await login(request);
          firebaseError.current = "";
          return {
            userCredential,
            idToken,
            credential,
            userExists,
            onboarding_complete: loginResponse?.onboarding_complete ?? false,
          };
          // return userCredential;
        } catch (error: any) {
          if (error.code === "auth/account-exists") {
            // Handle existing account
            firebaseError.current = `This phone number is already associated with an account. ${
              error.email
                ? `Please sign in with your email (${error.email})`
                : "Please sign in to your existing account"
            }`;
          }
          throw error;
        }
      } else {
        throw new Error("No verification ID found.");
      }
    } catch (error) {
      if ((error as Error).message === "Firebase: Error (auth/invalid-verification-code).") {
        firebaseError.current = "Invalid verification code";
      } else firebaseError.current = (error as Error).message;
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const getGoogleCredential = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      return credential;
    } catch (error) {
      if (error instanceof Error && "code" in error && error.code === "auth/credential-already-in-use") {
        firebaseError.current = "Google account is already linked to another account";
      } else {
        firebaseError.current = (error as Error).message;
      }
    }
  };

  const getEmailPasswordCredential = async (email: string, password: string) => {
    try {
      const credential = EmailAuthProvider.credential(email, password);
      return credential;
    } catch (error) {
      if (error instanceof Error && "code" in error && error.code === "auth/credential-already-in-use") {
        firebaseError.current = "Email is already linked to another account";
      } else {
        firebaseError.current = (error as Error).message;
      }
    }
  };

  const unlinkProvider = async (providerId: string) => {
    const auth = getAuth();
    try {
      if (!auth.currentUser) {
        throw new Error("No user signed in");
      }
      const resp = await unlink(auth.currentUser, providerId);
    } catch (error) {
      firebaseError.current = (error as Error).message;
    }
  };

  const linkEmailPassword = async (email: string, password: string) => {
    const auth = getAuth();
    try {
      if (auth.currentUser) {
        // Create email/password credential
        const emailCredential = EmailAuthProvider.credential(email, password);
        // Link the credential to the current user
        const userCred = await linkWithCredential(auth.currentUser, emailCredential);
        const idToken = await userCred.user.getIdToken();
        return true;
      } else {
        throw new Error("No user is currently signed in");
      }
    } catch (error) {
      if (error instanceof Error && "code" in error && error.code === "auth/provider-already-linked") {
        firebaseError.current = "Phone number is already linked to another account";
      } else {
        firebaseError.current = (error as Error).message;
      }
      return false;
    }
  };

  async function login(request: { idToken: string; rememberMe: boolean }) {
    try {
      const response = await fetch(`${baseUrl}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error during login:", error);
      throw error;
    }
  }

  return {
    requestOTP,
    verifyOTP,
    isLoading,
    firebaseError: firebaseError.current,
    getGoogleCredential,
    getEmailPasswordCredential,
    unlinkProvider,
    linkEmailPassword,
    handleReset,
  };
};

export default useFirebaseServices;
