"use client";
import { ChangeEvent, KeyboardEvent, useEffect } from "react";
import { useSearchParams } from "next/navigation";
import { Suspense, useState } from "react";
import useFirebaseServices from "@/firebase/firbaseServices";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth } from "@/firebase/firebaseApp";
import { useRouter } from "next/navigation";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useLogin } from "@/src/lib/api/useLogin";
import CustomLoadingSpinner from "../../../../components/custom_loading_spinner/custom_loading_spinner";
//import { getGoogleCredential, signInWithGoogle } from "../../../../lib/api/signInWithGoogle";

const OtpPage = () => {
  const { verifyOTP, isLoading, firebaseError, linkEmailPassword, unlinkProvider } = useFirebaseServices();
  const { email, password } = useSelector((state: RootState) => state.register);
  const { handleCallbackUrl } = useLogin();
  const router = useRouter();
  const searchParams = useSearchParams();
  const callback: string = searchParams?.get("callback") || "";
  const mobileRef = searchParams?.get("mobileRef") || "";
  const ref = searchParams?.get("ref") || "";
  const phoneNumber = searchParams?.get("phoneNumber");
  console.log("hi mom", phoneNumber);
  const method = searchParams?.get("method");
  const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
  const [error, setError] = useState("");
  const credential = useSelector((state: RootState) => state.register.credential);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendTimeout, setResendTimeout] = useState(10);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (resendTimeout > 0) {
        setResendTimeout(resendTimeout - 1);
      } else {
        setResendDisabled(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [resendTimeout]);

  const handleVerifyOTP = async (e: React.FormEvent) => {
    e.preventDefault();
    const otpString = otp.join("");

    async function handleCompletedExternalLogin() {
      const url = await handleCallbackUrl(atob(callback));
      if (url) {
        window.location.href = url;
      }
      setTimeout(() => {
        router.push("/return-to-app?callback=" + callback);
      }, 200);
    }

    try {
      console.log('1')
      const results = await verifyOTP(otpString);
      console.log('2')
      if (!firebaseError) {
        if (method === "email") {
          if (!credential || !results) throw new Error("error verifying OTP");
          try {
            // const success = await linkEmailPassword(email, password);
            // if (success) {
              if (results?.userExists) {
                if (callback) {
                  await handleCompletedExternalLogin();
                } else {
                  router.push("/dashboard");
                }
              } else {
                const params = new URLSearchParams();
                if (callback) params.append("callback", callback);
                if (mobileRef) params.append("mobileRef", mobileRef);
                if (ref) params.append("ref", ref);

                const queryString = params.toString();
                router.push(`/register/set-username${queryString ? `?${queryString}` : ""}`);
              }
            // }
          } catch (error) {
            console.log("error linking email password", error);
          }
        } else if (method === "google") {
          // await getGoogleCredential();
          //router.push("/register/set-name");
        } else {
          // normal phone login
          if (results?.userExists) {
            if (callback) {
              await handleCompletedExternalLogin();
            } else {
              router.push("/dashboard");
            }
          } else {
            const params = new URLSearchParams();
            if (callback) params.append("callback", callback);
            if (mobileRef) params.append("mobileRef", mobileRef);
            if (ref) params.append("ref", ref);

            const queryString = params.toString();
            router.push(`/register/set-username${queryString ? `?${queryString}` : ""}`);
          }
        }
      }
    } catch (error) {
      console.log("FIREBASE ERROR", firebaseError);
      setError(error instanceof Error ? error.message : "An unknown error occurred");
    }
  };

  const handleResendCode = async () => {
    try {
      if (!phoneNumber) {
        console.error("Phone number not found.");
        return;
      }
      const recaptchaContainer = document.getElementById("recaptcha-container");
      if (!recaptchaContainer) {
        console.error("Recaptcha container not found.");
        return;
      }

      const recaptchaVerifier = new RecaptchaVerifier(
        recaptchaContainer,
        {
          size: "invisible", // or 'normal' if you want to see the reCAPTCHA
        },
        auth,
      );
      const cleanedPhoneNumber = "+1" + phoneNumber.replace(/\D+/g, "");
      const confirmationResult = await signInWithPhoneNumber(auth, cleanedPhoneNumber, recaptchaVerifier);
      sessionStorage.setItem("verificationId", confirmationResult.verificationId);

      console.log("OTP resent successfully");
    } catch (error) {
      console.error("Error resending OTP:", error);
      setError("Failed to resend OTP. Please try again.");
    }

    setResendDisabled(true);
    setResendTimeout(10);
    setOtp(Array(6).fill(""));
  };

  return (
    <div>
      <div className="-mt-20 flex h-screen flex-col items-center justify-center">
        <div className="md:px-40 flex-col justify-center gap-y-3 font-sans">
          <h1 className="font-sfpro-heavy self-center text-4xl font-bold">We sent an OTP to</h1>
          <p className="font-sfpro-medium self-center text-md font-semibold text-gray-400">{phoneNumber}</p>
          {(error || firebaseError) && (
            <p className="font-sfpro-medium self-center text-md font-semibold text-red">{error || firebaseError}</p>
          )}
          <div className="flex w-[400px] flex-col space-y-4 self-center">
            <OtpInput length={6} otp={otp} setOtp={setOtp} />
            <div className="flex w-full flex-col items-center justify-between">
              <button
                className="mt-2 flex w-[62%] flex-row items-center justify-center rounded-2xl border bg-black p-3 font-medium text-white"
                data-variant="black"
                data-size="thin"
                onClick={handleVerifyOTP}
                disabled={isLoading}
              >
                {isLoading ? <CustomLoadingSpinner spinnerColor="text-white" /> : "Verify"}
              </button>

              <button
                className={`mt-4 flex w-[62%] flex-row items-center justify-center rounded-2xl border border-gray-500 p-3 font-medium text-black ${resendDisabled ? "bg-gray-200 opacity-35" : ""}`}
                data-variant="black"
                data-size="thin"
                onClick={handleResendCode}
                disabled={resendDisabled}
              >
                {resendDisabled ? `Resend in ${resendTimeout} seconds` : "Resend Code"}
              </button>
              <div id="recaptcha-container"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OtpPageSuspense = () => {
  return (
    <Suspense fallback={"Loading...."}>
      <OtpPage />
    </Suspense>
  );
};
interface OtpInputProps {
  length: number;
  otp: string[];
  setOtp: (otp: string[]) => void;
}

export const OtpInput: React.FC<OtpInputProps> = ({ length, otp, setOtp }) => {
  const handleChange = (element: HTMLInputElement, index: number) => {
    if (isNaN(Number(element.value))) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus on next input box if current value is filled
    if (element.nextElementSibling && element.value) {
      (element.nextElementSibling as HTMLInputElement).focus();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === "Backspace" && !otp[index]) {
      const previousElement = event.currentTarget.previousElementSibling as HTMLInputElement;
      if (previousElement) previousElement.focus();
    }
  };

  return (
    <div className="flex items-center justify-center space-x-2 self-center lg:w-[610px]">
      {otp.map((data, index) => (
        <input
          key={index}
          type="number"
          maxLength={1}
          value={data}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target, index)}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index)}
          className="h-[70px] w-[50px] rounded-lg bg-[#000000] bg-opacity-5 text-center text-2xl font-semibold outline-none"
        />
      ))}
    </div>
  );
};

export default OtpPageSuspense;
