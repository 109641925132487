import React from "react";

interface CustomLoadingSpinner {
    spinnerColor?: string;
}

const CustomLoadingSpinner: React.FC<CustomLoadingSpinner> = ({ spinnerColor }) => {
    return (
        <div
            className={`${spinnerColor ? spinnerColor : 'text-green'} `}>
            <div
                className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
            </div>
        </div>
    )
}

export default CustomLoadingSpinner;