// src/redux/slices/registerSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {Address} from "../../@types/shipping";
import { seller } from "@/src/@types/seller";
import { EmailAuthCredential, OAuthCredential } from "firebase/auth";


const initialState: seller = {
  name: "",
  userName: "",
  email: "",
  password: "",
  passwordConfirm: "",
  loadRegister: false,
  error: "",
  address: {
    city: "",
    country: "",
    state: "",
    street1: "",
    zip: "",
  },
  account_linked: false,
  balance: 0,
  billing: {
    exceeded_limit: false,
    has_used_free_trial: false,
    session_id: "",
    stripe_customer_id: "",
    subscription_expiration_date: "",
    subscription_id: "",
    subscription_status: ""
  },
  difference: 0,
  id: "",
  onboarded: false,
  percentage: 0,
  phone_number: "",
  pickup_eligible: false,
  plan_tier: "",
  username: "",
  profile_picture_url: ""
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setSeller(state, action: PayloadAction<seller>) {
      return action.payload;
    },
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setUserName(state, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
    setPhoneNumber(state, action: PayloadAction<string>) {
      state.phone_number = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setPasswordConfirm(state, action: PayloadAction<string>) {
      state.passwordConfirm = action.payload;
    },
    setLoadRegister(state, action: PayloadAction<boolean>) {
      state.loadRegister = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setAddress(state, action: PayloadAction<Address>) {
      state.address = action.payload;
    },
    resetRegisterState(state) {
      state = initialState;
    },
    setCredential(state, action: PayloadAction<OAuthCredential | EmailAuthCredential>) {
      state.credential = action.payload;
    }
  },
});

export const {
  setName,
  setUserName,
  setPhoneNumber,
  setEmail,
  setPassword,
  setPasswordConfirm,
  setLoadRegister,
  setError,
  resetRegisterState,
  setAddress,
  setCredential,
} = registerSlice.actions;

export default registerSlice.reducer;
